import React from 'react';
import ModalVideo from 'react-modal-video';
import ScrollAnimation from 'react-animate-on-scroll';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import { DefaultProps, PropTypes } from '../../../core/Utils/SitecoreFields';

class ProductTestimonial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  render() {
    const {
      title,
      image,
      quote,
      videoId,
      ariaLabel,
      content,
      isSecondary,
      hasFade,
    } = this.props.fields;
    const contentBlock = (
      <div
        className={`product-testimonial ${
          isSecondary.value ? 'is-secondary' : ''
        }`}
      >
        <div className="product-testimonial-container l-padding">
          <Text field={title} tag="h2" />
          <div className="testimonial-container">
            {videoId && videoId.value ? (
              <React.Fragment>
                <button
                  type="button"
                  className="image-container video-play-icon"
                  onClick={() => this.setState({ isModalOpen: true })}
                  aria-label={ariaLabel ? ariaLabel.value : 'Watch the video'}
                >
                  <Image field={image} />
                </button>
                <ModalVideo
                  channel="youtube"
                  isOpen={this.state.isModalOpen}
                  onClose={() => this.setState({ isModalOpen: false })}
                  videoId={videoId.value}
                  youtube={{
                    autoplay: 1,
                    enablejsapi: 1,
                    rel: 0,
                  }}
                />
              </React.Fragment>
            ) : (
              <div className="image-container">
                <Image field={image} />
              </div>
            )}
            <div className="blockquote-container">
              <RichText field={quote} />
            </div>
          </div>
          <div className="content-container" role="presentation">
            <RichText field={content} />
          </div>
        </div>
      </div>
    );

    return hasFade && hasFade.value ? (
      <ScrollAnimation
        animateIn="toFadeInUp"
        animateOnce
        delay={100}
        duration={0.5}
      >
        {contentBlock}
      </ScrollAnimation>
    ) : (
      contentBlock
    );
  }
}

ProductTestimonial.propTypes = PropTypes.SitecoreItem.inject({
  title: PropTypes.SingleLineText,
  quote: PropTypes.SingleLineText,
  content: PropTypes.RichText,
  isSecondary: PropTypes.Checkbox,
  image: PropTypes.Image,
  videoId: PropTypes.SingleLineText,
  ariaLabel: PropTypes.SingleLineText,
});

ProductTestimonial.defaultProps = {
  title: DefaultProps.SingleLineText,
  quote: DefaultProps.SingleLineText,
  content: DefaultProps.RichText,
  isSecondary: DefaultProps.Checkbox,
  image: DefaultProps.Image,
  videoId: DefaultProps.SingleLineText,
  ariaLabel: DefaultProps.SingleLineText,
};

export default ProductTestimonial;
